import { ref } from 'vue'
import { defineStore } from 'pinia'

import { listAddresses, createAddress, updateAddress } from '@rechargeapps/storefront-client'
import type { Address, CreateAddressRequest, UpdateAddressRequest } from '@rechargeapps/storefront-client'
import { useAuthStore } from './auth'
import { useAppStore } from '@/stores'

export const useAddressStore = defineStore('recharge/address', () => {
  const { alert, handleError } = useAppStore()
  const { useSession } = useAuthStore()

  const loading = ref(false)
  const addresses = ref<Address[]>([])

  const loadAddresses = async () => {
    loading.value = true
    const response = await listAddresses(useSession())
    addresses.value = response.addresses
    loading.value = false
    return addresses
  }

  const updateAddresses = (address: Address) => {
    const index = addresses.value.findIndex(i => i.id === address.id)
    if (index < 0) {
      addresses.value.push(address)
    } else {
      addresses.value.splice(index, 1, address)
    }
  }

  const update = async (id: number, values: UpdateAddressRequest) => {
    try {
      const address = await updateAddress(useSession(), id, values)
      updateAddresses(address)
      alert('Your address has been updated.')
      return address
    } catch (e) {
      handleError(e)
    }
  }

  const create = async (values: CreateAddressRequest) => {
    try {
      const session = useSession()
      const payload: CreateAddressRequest = {
        ...values,
        customer_id: Number(session.customerId)
      }
      const address = await createAddress(session, payload)
      updateAddresses(address)
      return address
    } catch (e) {
      handleError(e)
    }
  }

  const useAddresses = () => {
    if (!addresses.value.length) {
      loadAddresses()
    }
    return addresses
  }

  const reset = () => {
    loading.value = false
    addresses.value = []
  }

  return {
    loading,
    alert,
    loadAddresses,
    useAddresses,
    update,
    create,
    addresses,
    reset
  }
})
